<template>
  <div>
    <v-dialog v-model="isSelectRoom" persistent max-width="500">
      <v-card>
        <v-card-title>
          {{ $t('SelectDoctorAndRoom') }}
        </v-card-title>
        <v-form ref="formSelectRoom" @submit.prevent="updateDoctorAndRoom">
          <v-card-text>
            <v-autocomplete
              v-model.trim="user_id"
              :items="userList"
              :label="$t('select_inspectors')"
              :no-data-text="`${$t('no_data')} !`"
              item-text="user_fullname"
              :rules="[required]"
              item-value="user_id"
              :auto-select-first="true"
              outlined
              dense
            ></v-autocomplete>
            <v-autocomplete
              v-model.trim="room_id"
              :items="roomList"
              :label="$t('select_examination_room')"
              :no-data-text="`${$t('no_data')} !`"
              item-text="room_name"
              item-value="room_id"
              :rules="[required]"
              :auto-select-first="true"
              outlined
              dense
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">
              {{ $t('confirm') }}
            </v-btn>
            <v-btn color="secondary" outlined @click="$emit('update:is-select-room', false)">
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import router from '@/router'
import userRole from '@/api/userRole'
import examinationRoom from '@/api/systemSetting/examinationRoom'
import { required } from '@/@core/utils/validation'
import editCheck from '@/api/queue/editCheck'
import store from '@/store'
import { i18n } from '@/plugins/i18n'
export default {
  model: {
    prop: 'isSelectRoom',
    event: 'update:is-select-room',
  },
  props: {
    isSelectRoom: {
      type: Boolean,
      default: false,
    },
    opdData: {
      type: Object,
      default: () => ({}),
    },
    checkingStatus: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const roomList = ref([])
    const userList = ref([])
    const user_id = ref('')
    const room_id = ref('')
    const loading = ref(false)
    const opdDataLocal = ref({})

    const { userRoleList } = userRole
    const { examinationRoomList } = examinationRoom
    const { checkEditUpdate, changeRoomDoctor } = editCheck

    const getUserList = () => {
      userRoleList({
        role_id: [1, 3],
      }).then(res => {
        userList.value = res
      })
    }

    const getRoomList = () => {
      examinationRoomList({
        searchtext: '',
        room_status_id: 1,
      }).then(res => {
        roomList.value = res.data
      })
    }

    watch(
      () => props.isSelectRoom,
      val => {
        if (val) {
          getUserList()
          getRoomList()
          opdDataLocal.value = JSON.parse(JSON.stringify(props.opdData))
          user_id.value = opdDataLocal.value.user_id

          room_id.value = opdDataLocal.value.room_id
        }
      },
    )

    const updateDoctorAndRoom = () => {
      loading.value = true
      opdDataLocal.value.room_id = room_id.value
      opdDataLocal.value.user_id = user_id.value
      if (props.checkingStatus == 'checking') {
        changeRoomDoctor({
          checkq_id: opdDataLocal.value.checkq_id,
          opd_id: opdDataLocal.value.opd_id,
          user_id: opdDataLocal.value.user_id,
          room_id: opdDataLocal.value.room_id,
          lang: i18n.locale,
        }).then(res => {
          store.commit('app/ALERT', {
            message: res.message,
            color: res.response ? 'success' : 'error',
          })

          if (res.response) {
            emit('update:is-select-room', false)
            loading.value = false
            emit('updateRoomSuccess')
          } else {
            loading.value = false
          }
        })
      } else {
        checkEditUpdate(opdDataLocal.value).then(res => {
          store.commit('app/ALERT', {
            message: res.message,
            color: res.response ? 'success' : 'error',
          })

          if (res.response) {
            router.push({
              name: 'EditChecking',
              params: { id: opdDataLocal.value.opd_id },
            })
          } else {
            loading.value = false
          }
        })
      }
    }

    return {
      roomList,
      userList,
      user_id,
      loading,
      room_id,
      required,
      updateDoctorAndRoom,
    }
  },
}
</script>

<style lang="scss" scoped></style>
